import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import { HandleModalCartType } from './modalCart.model';
import DetailModalCart from './DetailModalCart';
import useClickOutside from '../../../hooks/useClickOutside';
import useModalCart from '../../../hooks/useModalCart';

interface ModalCartProps {
  goNextRoute: string;
}

const ModalCart = ({ goNextRoute }: ModalCartProps): JSX.Element => {
  const { push } = useRouter();
  const modalCartRef = useRef<HTMLDivElement>();
  const { openModal, items: itemsBasket, setOpenModal } = useModalCart();
  const { asPath } = useRouter();

  const clickOutModal = useClickOutside<HTMLDivElement>(() => {
    if (openModal === HandleModalCartType.Open) setOpenModal(HandleModalCartType.Close);
  }, ['quantyBtn', 'icon trash', 'HandleButtonBuyComponent']);

  const handleClickGoToCheckout = (): void => {
    push(goNextRoute);
  };

  if (['ebook-cart', 'cart'].some((route) => asPath.includes(route))) {
    return null;
  }

  return (
    <>
      <div id="modalCart" className={`modalCart ${openModal || 'hiddenModal'}`} ref={modalCartRef}>
        <div className="articleWrapper" ref={clickOutModal}>
          <DetailModalCart />
        </div>

        <div className="buttonWrapper">
          {itemsBasket?.length ? (
            <button type="button" className="buyButton" onClick={handleClickGoToCheckout}>
              FINALIZAR COMPRA
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ModalCart;
