import { useEffect, useRef, MutableRefObject } from 'react';

/**
 * Checks if clicked element should trigger click outside, based on its clases.
 *
 * Included and excluded classes work as containers, meaning that any element nested inside
 * them works as excluded/included element.
 *
 * @param {HTMLElement} element - Element clicked
 * @param {string} excluded - Class to ignore (works as a container)
 * @param {string} included - Class to include (works as a container)
 */
const checkClasses = (
  element: HTMLElement,
  excluded: string | string[],
  included: string,
): boolean => {
  if (included) {
    const includedElements = Array.from(document.getElementsByClassName(included));
    const isIncluded = includedElements.some((current) => current.contains(element));
    if (!isIncluded) {
      return false;
    }
  }
  if (excluded) {
    if (typeof excluded === 'string') return !element.className.includes(excluded);

    return !excluded.some((ex) => element.className.includes(ex));
  }
  return true;
};

/**
 * Detects click outside and element and triggers a function.
 *
 * @param {() => void} callback - Function to execute
 * @param {string} excludeClass - Class to ignore when click (and every child element)
 * @param {string} includeClass - Class to include on click (and every child element)
 *
 * @returns {MutableRefObject<HTMLElement>} Ref to the element which should listen outside click.
 */
const useClickOutside = <T extends HTMLElement>(
  callback: () => void,
  excludeClass?: string | string[],
  includeClass?: string,
): MutableRefObject<T> => {
  const ref = useRef(null);
  const handleClick: EventListener = (e) => {
    if (ref && ref.current && ref.current) {
      const isInClass = checkClasses(e.target as T, excludeClass, includeClass);
      if (!ref.current.contains(e.target) && isInClass) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return (): void => {
      document.removeEventListener('click', handleClick);
    };
  });

  return ref;
};

export default useClickOutside;
