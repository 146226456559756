import { HeaderState } from '../../layouts/Header/header.model';
import ActionHeader, { TypeActionHeader } from './actionHeader';

const reducerHeader = (state: HeaderState, action: ActionHeader): HeaderState => {
  switch (action.type) {
    case TypeActionHeader.set_state: {
      return {
        ...state,
        ...action.header,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducerHeader;
