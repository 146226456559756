import React from 'react';
import Link from '../../components/common/link/Link';
import Button from '../../components/common/forms/Button';
import Icon from '../../components/common/Icon';
import Image from '../../components/common/Image';
import ModalCart from './ModalCart/ModalCart';
import useModalCart from '../../hooks/useModalCart';
import { HandleModalCartType } from './ModalCart/modalCart.model';

interface HeaderMobileProps {
  fullScreen?: boolean;
  sidebar?: boolean;
  cartFull?: boolean;
  toggleSidebar: (event?) => void;
  handleClose: () => void;
  totalCart: number;
  cartActive: string;
  cartLink: string;
}

const HeaderMobile = ({
  fullScreen,
  sidebar,
  toggleSidebar,
  handleClose,
  totalCart,
  cartActive,
  cartFull,
  cartLink,
}: HeaderMobileProps): JSX.Element => {
  const { setOpenModal: setShowCartModal, openModal: showCartModal } = useModalCart();

  const handleClickCartModal = (): void => {
    if ([HandleModalCartType.Close, HandleModalCartType.Initial].includes(showCartModal)) {
      setShowCartModal(HandleModalCartType.Open);
      return;
    }

    setShowCartModal(HandleModalCartType.Close);
  };

  return (
    <header className={`header mobile ${fullScreen ? 'fullscreen' : ''}`}>
      {!fullScreen && (
        <Button noStyle aria="burguer-menu" onClick={toggleSidebar}>
          <Icon className={sidebar ? ' close' : 'menu'} nameAriaLabel="closeSearchIcon" />
        </Button>
      )}

      <Link className="logoMobile" onClick={handleClose} href="/">
        <Image addCdnPrefix src="img/logo-header-mobile.svg" alt="logo" width="100" height="100" />
      </Link>

      {!fullScreen ? (
        <Link className="iconLink cartLink newCartLink" onClick={handleClickCartModal}>
          <img
            src="/img/cart.svg"
            alt="carrito"
            className={`cartIco ${totalCart ? 'full' : ''} ${cartActive}`}
          />

          {cartFull ? (
            <span className={`cartCounter counterNew ${cartActive}`}>{totalCart || null}</span>
          ) : null}
        </Link>
      ) : null}

      <ModalCart goNextRoute={cartLink} />
    </header>
  );
};

export default HeaderMobile;
