/* eslint-disable import/prefer-default-export */

import { Area, GetAreaDocument, GetAreaQuery } from '../../../graphqlTypes';
import { initializeApollo } from '../../../graphql/apollo';
import { Response } from '../../../models/response.model';
import { getNewBannersDocument } from '../../../graphql/queries';

export const getAreas = async (): Promise<Response<Area>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { getArea },
    } = await query<GetAreaQuery>({
      query: GetAreaDocument,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!getArea,
      data: getArea,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

export const getNewBanners = async (): Promise<{
  ok: boolean;
  data?: {
    _id: string;
    link: string;
    active: boolean;
    imageUrl: string;
  };
}> => {
  try {
    const { query } = initializeApollo();

    const { data } = await query({
      query: getNewBannersDocument,
      fetchPolicy: 'cache-first',
      variables: { limit: 1, skip: 0 },
    });

    return {
      ok: true,
      data: data.getNewBanners?.[0],
    };
  } catch (error: any) {
    return {
      ok: false,
    };
  }
};
