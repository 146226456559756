import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useWindowSize from '../../hooks/useWindowSize';
import { getNewBanners } from '../../components/common/comingSoonBanner/comingSoonBanner.service';

interface BannerNewProps {
  className: string;
  short?: boolean;
  getScrollElement?: () => Scrollbars;
}

const BannerNew = ({ className, short, getScrollElement }: BannerNewProps): JSX.Element => {
  const { asPath } = useRouter();
  const { documentWidth } = useWindowSize();
  const isTop = getScrollElement && getScrollElement().getScrollTop();
  const [banner, setBanner] = useState<{
    _id: string;
    link: string;
    active: boolean;
    imageUrl: string;
  } | null>(null);

  useEffect(() => {
    getNewBanners().then((res) => {
      setBanner(res.data || null);
    });
  }, []);

  if (documentWidth > 1024 && short) {
    return null;
  }

  if (asPath.includes('ficha')) {
    return null;
  }

  if (!banner || !banner?.active) {
    return null;
  }

  if (isTop > 100 && !short) {
    return null;
  }

  return (
    <Link href={banner.link}>
      <img alt="banner new" src={banner.imageUrl} className={className} />
    </Link>
  );
};

export default BannerNew;
