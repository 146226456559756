import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cookies from 'cookies-js';

import { emptyCartUser, getBasketQueryService } from '../../components/cart/services/cart.service';
import { SCREEN_SIZES } from '../../utils/constants';
import { HeaderProps } from './header.model';
import useWindowSize from '../../hooks/useWindowSize';
import userReactiveVar, { useReactiveVariableUser } from '../../hooks/useReactiveVariableUser';
import { Basket } from '../../graphqlTypes';
import reducerHeader from '../../reducer/header/reducerHeader';
import {
  getDataFromStorage,
  isClient,
  NameStorage,
  removeDataInStorage,
} from '../../utils/services/localStorage.service';
import HeaderMobile from './HeaderMobile';
import HeaderDesktop from './HeaderDesktop';
import { getTypeCartLink } from '../../components/cart/utils/cart.utils';
import { CartLink } from '../../components/cart/models/model.cart';
import { addToBasketMutation } from '../../components/ficha/services/handleButtonBuy.service';
import useModalCart from '../../hooks/useModalCart';

const Header = ({
  large,
  showLoginFn,
  user,
  setSearching,
  setLarge,
  removeAreaOfInterest,
  fullScreen,
  toggleSidebar,
  sidebar,
  searchScrollTop,
  changeSearching,
  setFullScreen,
  keys,
  search,
  results,
  buscando,
  category: categoryProps,
  getScrollElement,
}: HeaderProps): JSX.Element => {
  const { asPath, back, replace } = useRouter();
  const [state, setState] = useReducer(reducerHeader, {
    cartLink: '/cart',
    showModal: false,
    cartFull: true,
    tab: undefined,
  });
  const { documentWidth } = useWindowSize();
  const [cartActive, setCartActive] = useState<string>(null);
  const cartLink = useRef<CartLink>('/cart');

  const { setItems, totalCart } = useModalCart();

  const { refetchUser } = useReactiveVariableUser();

  const addBasketLocalStorageInUser = async (): Promise<void> => {
    const basket = getDataFromStorage<Basket[]>(NameStorage.basket);
    if (!basket?.length) {
      return;
    }

    const { data } = await getBasketQueryService({ idUser: user._id });

    const [firstInBasketUser] = data?.profile?.basket || [];
    const [firstInBasket] = basket;

    if (firstInBasketUser) {
      const isBasketEbookAndUserBasketNoEbook =
        firstInBasket?.book?.product_type === 'ebook' &&
        firstInBasketUser?.book?.product_type !== firstInBasket?.book?.product_type;

      const isBasketNoEbookAndUserBasketEbook =
        firstInBasket?.book?.product_type !== 'ebook' &&
        firstInBasketUser?.book?.product_type === 'ebook';

      if (isBasketEbookAndUserBasketNoEbook || isBasketNoEbookAndUserBasketEbook) {
        await emptyCartUser({ idUser: user._id });
      }
    }

    Promise.all([
      basket.map((bask) =>
        addToBasketMutation({
          idUser: user._id,
          idProduct: bask._id,
          ean: bask.ean,
          units: bask.units,
        }),
      ),
    ]).then(() => {
      refetchUser();
      removeDataInStorage(NameStorage.basket);
    });
  };

  useEffect(() => {
    if (!user) return;

    addBasketLocalStorageInUser();
  }, [user]);

  const getTotalUnitsAndCartType = (): void => {
    if (user) {
      cartLink.current = getTypeCartLink(user?.profile?.basket);
      setItems(user.profile.basket);
      return;
    }

    if (isClient()) {
      const basket = localStorage.getItem('basket');
      if (basket) {
        const basketTotal: Basket[] = JSON.parse(basket);
        cartLink.current = getTypeCartLink(basketTotal);
        setItems(basketTotal);
        return;
      }
    }

    setItems(null);
  };

  useEffect(() => {
    getTotalUnitsAndCartType();
  }, [user]);

  useEffect(() => {
    if (state.tab?.includes('/cart')) {
      setCartActive('active');
    }
  }, [state.tab]);

  const showLogin = (): void => {
    if (showLoginFn) showLoginFn();
  };

  const handleClose = (): void => {
    const [, category] = state.tab || [];
    if (category === 'areas') {
      back();
    }
    if (setSearching) {
      setSearching('');
    }
    setLarge(false);
  };

  const logOut = (): void => {
    if (!user) return;

    cookies.expire('token');
    if (removeAreaOfInterest) {
      removeAreaOfInterest();
    }
    const route = asPath;
    if (route === '/cuenta') {
      replace('/', undefined, { shallow: true }).then(() => {
        userReactiveVar(null);
      });

      return;
    }
    userReactiveVar(null);
  };

  const toggleLargeSearch = (): void => {
    setLarge(!large);
  };

  useEffect(() => {
    setLarge(false);
  }, [asPath]);

  if (documentWidth >= SCREEN_SIZES.MIN_DESK) {
    return (
      <HeaderDesktop
        getScrollElement={getScrollElement}
        buscando={buscando}
        searchScrollTop={searchScrollTop}
        results={results}
        large={large}
        cartActive={cartActive}
        cartLink={cartLink.current}
        changeSearching={changeSearching}
        fullScreen={fullScreen}
        handleClose={handleClose}
        onCloseModal={setState}
        search={search}
        setFullScreen={setFullScreen}
        showLogin={showLogin}
        showModal={state.showModal}
        tab={state.tab}
        toggleLargeSearch={toggleLargeSearch}
        totalCart={totalCart}
        user={user}
        cartFull={state.cartFull}
        keys={keys}
        logOut={logOut}
        category={categoryProps}
      />
    );
  }

  return (
    <HeaderMobile
      cartActive={cartActive}
      sidebar={sidebar}
      fullScreen={fullScreen}
      cartFull={state.cartFull}
      handleClose={handleClose}
      toggleSidebar={toggleSidebar}
      totalCart={totalCart}
      cartLink={cartLink.current}
    />
  );
};

export default React.memo(Header);
