import React from 'react';
import { useRouter } from 'next/router';
import Scrollbars from 'react-custom-scrollbars';

import ShowConditionsButton from '../../components/common/ShowConditionsButton';
import { nameImgDicount, titleDiscount } from './header.constant';
import { HandleModalCartType } from './ModalCart/modalCart.model';
import useModalCart from '../../hooks/useModalCart';
import Nav from '../Nav';
import Icon from '../../components/common/Icon';
import Link from '../../components/common/link/Link';
import Image from '../../components/common/Image';
import Button from '../../components/common/forms/Button';
import Search from '../../components/common/Search/Search';
import ShippingConditions from '../../staticPages/ShippingConditions';
import ActionHeader, { TypeActionHeader } from '../../reducer/header/actionHeader';
import { User } from '../../graphqlTypes';
import ModalCart from './ModalCart/ModalCart';
import BannerNew from './BannerNew';

interface HeaderDesktopProps {
  handleClose: () => void;
  large?: boolean;
  setFullScreen: (propState, value) => void;
  searchScrollTop?: boolean;
  fullScreen: boolean;
  search: string;
  results?: boolean;
  changeSearching: (value) => void;
  buscando: boolean;
  toggleLargeSearch: () => void;
  showModal: boolean;
  onCloseModal: (value: ActionHeader) => void;
  cartLink: string;
  totalCart: number;
  cartActive: string;
  cartFull?: boolean;
  showLogin: () => void;
  user: User;
  tab: string[];
  keys: unknown;
  logOut: () => void;
  category?: string;
  getScrollElement: () => Scrollbars;
}

const HeaderDesktop = ({
  handleClose,
  large,
  setFullScreen,
  searchScrollTop,
  fullScreen,
  search,
  results,
  changeSearching,
  buscando,
  toggleLargeSearch,
  showModal,
  onCloseModal,
  cartLink,
  totalCart,
  cartActive,
  cartFull,
  showLogin,
  user,
  tab,
  keys,
  category,
  logOut,
  getScrollElement,
}: HeaderDesktopProps): JSX.Element => {
  const { pathname, back, push } = useRouter();
  const { setOpenModal: setShowCartModal, openModal: showCartModal } = useModalCart();

  const handleAccountClick = (): void => {
    if (user) {
      push('/cuenta');
      return;
    }

    showLogin();
  };

  const handleClickCartModal = (): void => {
    if ([HandleModalCartType.Close, HandleModalCartType.Initial].includes(showCartModal)) {
      setShowCartModal(HandleModalCartType.Open);
      return;
    }

    setShowCartModal(HandleModalCartType.Close);
  };

  if (pathname?.indexOf('/areas') === 0) {
    return (
      <header className="header fullscreen">
        <div className="headerTop">
          <Button onClick={(): void => back()} className="closeAreas">
            <Icon className="close" nameAriaLabel="closeAreaIconDesktop" />
          </Button>

          <div className="logoWrapper">
            <Link className="logoHolder" href="/">
              <Image
                onClick={handleClose}
                addCdnPrefix
                src="img/logo-header-desk.svg"
                alt="logo-axon"
                height="60"
                width="200"
              />
            </Link>
          </div>
        </div>

        <div className="boxSearch areaSearch large">
          {large && (
            <Search
              disabled={!large}
              setFullScreen={setFullScreen}
              searchScrollTop={searchScrollTop}
              fullScreen={fullScreen}
              keys={keys}
              search={search}
              results={results}
              changeSearching={changeSearching}
              buscando={buscando}
              toggleSearch={toggleLargeSearch}
              desktop
            />
          )}
        </div>

        {!large ? (
          <div
            role="none"
            onClick={toggleLargeSearch}
            className="boxSearch areaSearch large withoutFocus"
          >
            <Search
              setFullScreen={setFullScreen}
              searchScrollTop={searchScrollTop}
              fullScreen={fullScreen}
              buscando={buscando}
              keys={keys}
              search={search}
              results={results}
              disabled={!large}
              changeSearching={changeSearching}
              desktop
            />
          </div>
        ) : null}
      </header>
    );
  }

  return (
    <header className={`header${fullScreen ? ' fullscreen' : ''} `}>
      <ShippingConditions
        show={showModal}
        setShowModal={(): void =>
          onCloseModal({
            type: TypeActionHeader.set_state,
            header: {
              showModal: false,
            },
          })
        }
      />

      <div className="headerTop">
        <div className={`emptyPositioner ${large ? 'large' : ''}`}>
          {!large && (
            <>
              <ShowConditionsButton
                onClick={(): void =>
                  onCloseModal({
                    type: TypeActionHeader.set_state,
                    header: {
                      showModal: true,
                    },
                  })
                }
                height="50"
                width="240"
                freeDeliveryImage="freedeliveryheader"
                className="freeDelivery"
              />

              {['model', 'material', 'poster'].includes(category) ? (
                <img
                  alt={`descuento en ${titleDiscount[category]}}`}
                  src={`/img/freedelivery/${nameImgDicount[category]}.png`}
                  className="discountImg"
                />
              ) : null}
            </>
          )}
        </div>
        {['book'].includes(category) ? (
          <BannerNew className="bannerImg" getScrollElement={getScrollElement} />
        ) : null}

        <div className={`logoWrapper ${large ? 'large' : ''}`}>
          <Link className={`logoHolder ${large ? 'large' : ''}`} href="/">
            <Image
              onClick={handleClose}
              addCdnPrefix
              src="img/logo-header-desk.svg"
              alt="logo-axon"
              height="60"
              width="200"
            />
          </Link>
        </div>

        <div className={`userZone ${large ? 'large' : ''}`}>
          <div className={`boxSearch ${large ? 'large' : ''}`}>
            {large && (
              <Search
                setFullScreen={setFullScreen}
                searchScrollTop={searchScrollTop}
                fullScreen={fullScreen}
                keys={keys}
                search={search}
                buscando={buscando}
                results={results}
                changeSearching={changeSearching}
                toggleSearch={toggleLargeSearch}
                desktop
              />
            )}
          </div>

          {!large && (
            <button
              type="button"
              aria-label="Buscar"
              className="search short"
              onClick={toggleLargeSearch}
            >
              Buscar
              <Icon className="search" nameAriaLabel="searchIconDesktop" />
            </button>
          )}

          <Link className="iconLink cartLink cartIcolink" onClick={handleClickCartModal}>
            <img
              src="/img/cart.svg"
              alt="carrito"
              className={`cartIco ${totalCart ? 'full' : ''} ${cartActive}`}
            />
            {cartFull && (
              <span className={`cartCounter counterNew ${cartActive}`}>{totalCart || null}</span>
            )}
          </Link>

          <div className={`userLogged ${user ? 'active' : ''}`}>
            <Link className="iconLink link userIcolink" onClick={handleAccountClick}>
              <img
                src="/img/user.svg"
                alt="user"
                className={`userIco ${totalCart ? 'full' : ''} ${cartActive}`}
              />
              {user && <span>{`Hola ${user?.profile?.firstName}`}</span>}
            </Link>

            {user && (
              <Button className="iconLink" noStyle onClick={logOut}>
                <Icon className="arrow-right" nameAriaLabel="arrowRightIcon" />
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="headerBottom">
        <div className="nav">
          <Nav ruta={tab} />
        </div>
      </div>
      <ModalCart goNextRoute={cartLink} />
    </header>
  );
};

export default HeaderDesktop;
