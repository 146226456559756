export const titleDiscount = {
  model: 'Modelos',
  material: 'Material',
  poster: 'Láminas',
};

export const nameImgDicount = {
  model: 'modelos',
  material: 'material',
  poster: 'laminas',
};
