import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import speakingUrl from 'speakingurl';

import Link from '../components/common/link/Link';
import constants from '../utils/constants';
import { getDataFromStorage, NameStorage } from '../utils/services/localStorage.service';

interface ComponentProps {
  ruta?: string[];
  hideMenu?: () => void;
}

const Nav = ({ hideMenu }: ComponentProps): JSX.Element => {
  const [areaOfInterest, setAreaOfInterest] = useState<string>(null);
  const { allProductCategoriesFull, allProductCategoriesP } = constants;
  const { query, asPath } = useRouter();
  const { area: areaQuery } = query;

  useEffect(() => {
    const area = getDataFromStorage<string>(NameStorage.areaOfInterestName);
    if (area && area !== 'null') {
      setAreaOfInterest(
        speakingUrl(area.replace(/, /g, '.'), {
          custom: ['.'],
        }),
      );
      return;
    }

    if (areaOfInterest) setAreaOfInterest(null);
  }, [areaQuery]);

  const handleChangeTab = (): void => {
    window.scroll(0, 0);
    if (hideMenu) {
      hideMenu();
    }
  };

  return (
    <div className="menu">
      <Link
        className={asPath.includes(allProductCategoriesP[0]) ? 'active' : ''}
        onClick={handleChangeTab}
        href={
          areaOfInterest
            ? `/${allProductCategoriesFull[0]}/${areaOfInterest}`
            : `/${allProductCategoriesFull[0]}`
        }
      >
        Libros
      </Link>
      <Link
        className={asPath.includes(allProductCategoriesP[5]) ? 'active' : ''}
        onClick={handleChangeTab}
        href={
          areaOfInterest
            ? `/${allProductCategoriesFull[5]}/${areaOfInterest}`
            : `/${allProductCategoriesFull[5]}`
        }
      >
        Ebooks
      </Link>
      <Link
        className={asPath.includes(allProductCategoriesP[1]) ? 'active' : ''}
        onClick={handleChangeTab}
        href={
          areaOfInterest ? `/${allProductCategoriesFull[1]}` : `/${allProductCategoriesFull[1]}`
        }
      >
        Modelos
      </Link>
      <Link
        className={asPath.includes(allProductCategoriesP[2]) ? 'active' : ''}
        onClick={handleChangeTab}
        href={
          areaOfInterest ? `/${allProductCategoriesFull[2]}` : `/${allProductCategoriesFull[2]}`
        }
      >
        Material
      </Link>
      <Link
        className={asPath.includes(allProductCategoriesP[4]) ? 'active' : ''}
        onClick={handleChangeTab}
        href={
          areaOfInterest ? `/${allProductCategoriesFull[4]}` : `/${allProductCategoriesFull[4]}`
        }
      >
        Láminas
      </Link>
    </div>
  );
};

export default React.memo(Nav);
