import { Response } from '../models/response.model';
import { initializeApollo } from '../graphql/apollo';
import {
  ModifyToBasketDocument,
  ModifyToBasketMutation,
  ModifyToBasketMutationVariables,
  RemoveToBasketDocument,
  RemoveToBasketMutation,
  RemoveToBasketMutationVariables,
  User,
} from '../graphqlTypes';

export const modifyBasketUser = async (
  variables: ModifyToBasketMutationVariables,
): Promise<Response<User>> => {
  try {
    const { mutate } = initializeApollo();

    const {
      data: { modifyToBasket },
    } = await mutate<ModifyToBasketMutation>({
      mutation: ModifyToBasketDocument,
      variables,
    });

    return {
      ok: !!modifyToBasket,
      data: modifyToBasket,
    };
  } catch (error) {
    const { message } = error as Error;
    return {
      ok: false,
      messageError: message,
    };
  }
};

export const deleteBasketUser = async (
  variables: RemoveToBasketMutationVariables,
): Promise<Response<User>> => {
  try {
    const { mutate } = initializeApollo();

    const {
      data: { removeToBasket },
    } = await mutate<RemoveToBasketMutation>({
      mutation: RemoveToBasketDocument,
      variables,
    });

    return {
      ok: !!removeToBasket,
      data: removeToBasket,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};
