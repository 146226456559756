import React from 'react';
import { useReactiveVar } from '@apollo/client';

import { CorrelationShort } from '../../../models/common.model';
import { gaCartAction } from '../../../utils/analytics';
import { deleteBasketUser, modifyBasketUser } from '../../../services/user.service';
import Link from '../../../components/common/link/Link';
import { Basket, Book, User } from '../../../graphqlTypes';
import Button from '../../../components/common/forms/Button';
import userReactiveVar from '../../../hooks/useReactiveVariableUser';
import { getRoot } from '../../../utils/utils';
import {
  getDataFromStorage,
  NameStorage,
  setDataInStorage,
} from '../../../utils/services/localStorage.service';
import useModalCart from '../../../hooks/useModalCart';

const DetailModalCart = (): JSX.Element => {
  const user = useReactiveVar(userReactiveVar);
  const { setItems, items: basket } = useModalCart();

  const deleteProductFromBasket = async (idProduct: string): Promise<void> => {
    const idUser = user?._id;

    if (idUser) {
      const { ok, data } = await deleteBasketUser({ idProduct, idUser });

      if (!ok) {
        return;
      }

      const newBasket = data.profile.basket as Basket[];
      const updateUserBasket: User = {
        ...user,
        profile: { ...user.profile, basket: newBasket },
      };

      userReactiveVar(updateUserBasket);

      return;
    }

    const newBasket: Basket[] = basket.filter((product) => product.book._id !== idProduct);

    setDataInStorage(NameStorage.basket, newBasket);
    setItems(newBasket);
  };

  const getHref = (
    category: string,
    idProduct: number,
    ean: string,
    titleFriendly = 'untitled',
  ): string => {
    const root = getRoot(category);

    const id = category === 'model' || category === 'material' ? idProduct : ean;
    return `${root + id}/${titleFriendly}`;
  };

  const gaCartAddHandler = (product: Book, operation: boolean): void => {
    const category = CorrelationShort[product?.product_type] || 'sin definir';

    const gaInfo = {
      id: product.id,
      name: product.title,
      category,
      brand: product.brand.name,
      price: product.priceWithDiscount || product.prices.sale,
      quantity: 1,
      action: operation ? 'add' : 'remove',
    };

    gaCartAction(gaInfo);
  };

  const modifyItemCard = async (product: Book, operation: boolean): Promise<void> => {
    let currentBasket = user
      ? user.profile?.basket
      : getDataFromStorage<Basket[]>(NameStorage.basket) || [];

    const { _id: idProduct } = product || {};
    const book = currentBasket.find((productInBasket) => productInBasket?.book?._id === idProduct);
    gaCartAddHandler(book.book, operation);

    if (user) {
      const { _id: idUser } = user;
      const isRemoveAllItemSameProduct = !operation && book.units === 1;
      const { ok, data } = isRemoveAllItemSameProduct
        ? await deleteBasketUser({ idProduct, idUser })
        : await modifyBasketUser({
            idProduct,
            idUser,
            operation,
          });

      if (ok) {
        const newBasket = data.profile.basket as Basket[];
        const updateUserBasket: User = {
          ...user,
          profile: { ...user.profile, basket: newBasket },
        };

        userReactiveVar(updateUserBasket);
      }

      return;
    }

    if (operation) {
      book.units++;
    } else if (book.units > 1) {
      book.units--;
    } else {
      currentBasket = currentBasket.filter((basketData) => basketData._id !== book._id);
    }

    setDataInStorage(NameStorage.basket, currentBasket);
    setItems(currentBasket);
  };

  if (!basket?.length) {
    return (
      <div className="itemPrice">
        <p className="sinProduct">No hay productos en el carrito</p>
      </div>
    );
  }

  return (
    <>
      <div className="items">
        {basket.map((product) => {
          return (
            <div className="item" key={product._id}>
              <Link
                href={getHref(
                  product.product_type,
                  product.book.id,
                  product.ean,
                  product.book.titleFriendly,
                )}
              >
                <img src={product.book.mainImg} alt="item" />
              </Link>
              <div className="w100 flex-col-cart">
                <div className="contenidoProd">
                  <p className="itemPrice">
                    {(product.book.priceWithDiscount * product.units).toLocaleString('de-DE', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    €
                  </p>
                  <div className="quantyPill">
                    <button
                      type="button"
                      className="quantyBtn"
                      onClick={(): Promise<void> => modifyItemCard(product.book, false)}
                    >
                      -
                    </button>
                    <span className="quanty">{product.units}</span>
                    <button
                      type="button"
                      className="quantyBtn plusCard"
                      onClick={(): Promise<void> => modifyItemCard(product.book, true)}
                    >
                      +
                    </button>
                  </div>
                  <div className="flexWrap">
                    <Button
                      noStyle
                      onClick={(): void => {
                        deleteProductFromBasket(product.book._id);
                      }}
                    >
                      <i className="icon trash" role="button" aria-label="trash" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="priceWrapper">
        <p>
          SUBTOTAL:
          <span className="taxes">(CON IMPUESTOS)</span>
        </p>
        <p>
          {basket === null
            ? 0
            : basket
                .map((product) => product.book.priceWithDiscount * product.units)
                .reduce((prev, curr) => prev + curr, 0)
                .toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          €
        </p>
      </div>
    </>
  );
};

export default DetailModalCart;
