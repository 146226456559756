/* eslint-disable import/prefer-default-export */

import { AddToBasketMutation, AddToBasketMutationVariables, User } from '../../../graphqlTypes';
import { addToBasket } from '../../../graphql/mutations';
import { initializeApollo } from '../../../graphql/apollo';
import { Response } from '../../../models/response.model';

export const addToBasketMutation = async (
  variables: AddToBasketMutationVariables,
): Promise<Response<User>> => {
  try {
    const { mutate } = initializeApollo();

    const {
      data: { addToBasket: addToBasketData },
    } = await mutate<AddToBasketMutation>({
      mutation: addToBasket,
      variables,
      fetchPolicy: 'network-only',
    });

    return {
      ok: !!addToBasketData,
      data: addToBasketData,
    };
  } catch (error: any) {
    return {
      ok: false,
      data: error?.message,
    };
  }
};
