import { HeaderState } from '../../layouts/Header/header.model';

export enum TypeActionHeader {
  'set_state',
}

interface ActionHeader {
  type: TypeActionHeader.set_state;
  header: Partial<HeaderState>;
}

export default ActionHeader;
